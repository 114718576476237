import {useEffect} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';
import {validatePassword} from '../utils/validation';

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  font-family: ${props => props.fontFamily};
`;

const UserInput = styled.input`
  width: 100%;
  height: 40px;
  margin: 10px 0;
  padding: 0px 16px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #ffffff;
  font-family: ${props => props.fontFamily};
`;

const Note = styled.div`
  font-size: 12px;
  color: grey;
`;

const Button = styled.button`
  width: 100%;
  height: 40px;
  margin-top: 10px;
  padding: 0px;
  border: none;
  border-radius: 20px;
  background-color: ${({disabled}) => (disabled ? '#a8dadc' : '#3ddbd1')};
  color: #ffffff;
  font-family: ${props => props.fontFamily};
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
`;

const SignupButton = styled.button`
  width: 100%;
  height: 40px;
  margin-top: 30px;
  padding: 0px;
  border: none;
  border-radius: 20px;
  background-color: ${({disabled}) => (disabled ? '#a8dadc' : '#3ddbd1')};
  color: #ffffff;
  font-family: ${props => props.fontFamily};
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
`;

const Text = styled.div`
  color: #030303;
  font-family: ${props => props.fontFamily};
`;

const ErrorText = styled.div`
  color: red;
`;

const OtpContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px; /* Space between input and button */
`;

const OTPInput = styled(UserInput)`
  flex: 3;
`;

const SendOtpButton = styled(Button)`
  flex: 1;
`;

// Add this styled component for the asterisk
const RequiredAsterisk = styled.span`
  color: red;
  margin-left: 4px;
`;

// Add this styled component for the required fields note
const RequiredFieldsNote = styled.p`
  font-size: 12px;
  color: #666;
  margin-bottom: 10px;
`;

const Signup = () => {
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [emailOtp, setEmailOtp] = useState('');
  const [phoneOtp, setPhoneOtp] = useState('');
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [referralCode, setReferralCode] = useState('');

  const [emailOtpCountdown, setEmailOtpCountdown] = useState(0);
  const [smsOtpCountdown, setSmsOtpCountdown] = useState(0);
  const [captchaToken, setCaptchaToken] = useState('');

  const [isEmailOtpVerified, setIsEmailOtpVerified] = useState(false);
  const [isSmsOtpVerified, setIsSmsOtpVerified] = useState(false);

  const [isEmailOtpFieldEnabled, setIsEmailOtpFieldEnabled] = useState(false);
  const [isPhoneOtpFieldEnabled, setIsPhoneOtpFieldEnabled] = useState(false);
  const [isPhoneFieldDisabled, setIsPhoneFieldDisabled] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [retypePasswordError, setRetypePasswordError] = useState('');

  useEffect(() => {
    const turnstileScript = document.createElement('script');
    turnstileScript.src =
      'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit';
    turnstileScript.async = true;
    document.body.appendChild(turnstileScript);

    turnstileScript.onload = () => {
      window.turnstile.render('#captcha-container', {
        sitekey: '0x4AAAAAAAjumj3SyXSdpY8y',
        callback: token => setCaptchaToken(token), // Correctly setting captchaToken
      });
    };

    return () => {
      document.body.removeChild(turnstileScript);
    };
  }, []);

  useEffect(() => {
    let emailOtpInterval = null;
    if (emailOtpCountdown > 0) {
      emailOtpInterval = setInterval(() => {
        setEmailOtpCountdown(prev => prev - 1);
      }, 1000);
    } else if (emailOtpCountdown === 0) {
      clearInterval(emailOtpInterval);
    }
    return () => clearInterval(emailOtpInterval);
  }, [emailOtpCountdown]);

  useEffect(() => {
    let smsOtpInterval = null;
    if (smsOtpCountdown > 0) {
      smsOtpInterval = setInterval(() => {
        setSmsOtpCountdown(prev => prev - 1);
      }, 1000);
    } else if (smsOtpCountdown === 0) {
      clearInterval(smsOtpInterval);
    }
    return () => clearInterval(smsOtpInterval);
  }, [smsOtpCountdown]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const referral = params.get('referralcode');
    if (referral) {
      setReferralCode(referral); // Set referral code if available in the URL
    }
  }, []);

  const validateEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\\.,;:\s@"]+\.)+[^<>()[\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = phone => {
    return /^\d{8}$/.test(phone); // Only allow 8 digits
  };

  const handleSendEmailOtp = async () => {
    try {
      setEmailOtpCountdown(60);
      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/sendEmailOTP`,
        {email},
      );
      setIsEmailOtpFieldEnabled(true);
    } catch (error) {
      console.error('Error sending email OTP:', error);
      if (error.response && error.response.status === 409) {
        setError(translate(language, 'signup.emailAlreadyInUse'));
      } else {
        setError(translate(language, 'signup.failedToSendEmailOTP'));
      }
    }
  };

  const handleSendPhoneOtp = async () => {
    if (!validatePhone(phone)) {
      setError(translate(language, 'signup.invalidHongKongPhone'));
      return;
    }
    try {
      setSmsOtpCountdown(60);
      await axios.post(`${process.env.REACT_APP_API_URI}/api/auth/sendSMSOTP`, {
        phone,
      });
      setIsPhoneOtpFieldEnabled(true);
    } catch (error) {
      console.error('Error sending SMS OTP:', error);
      if (error.response && error.response.status === 409) {
        setError(translate(language, 'signup.phoneAlreadyInUse'));
      } else {
        setError(translate(language, 'signup.failedToSendSMSOTP'));
      }
    }
  };

  const handleVerifyEmailOtp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/verifyEmailOTP`,
        {
          email,
          otp: emailOtp,
        },
      );
      if (response.status === 200) {
        setIsEmailOtpVerified(true);
      }
    } catch (error) {
      console.error('Error verifying email OTP:', error);
      setError(translate(language, 'signup.invalidEmailOTP'));
    }
  };

  const handleVerifyPhoneOtp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/verifySMSOTP`,
        {
          phone,
          otp: phoneOtp,
        },
      );
      if (response.status === 200) {
        setIsSmsOtpVerified(true);
        setIsPhoneFieldDisabled(true);
      }
    } catch (error) {
      console.error('Error verifying SMS OTP:', error);
      setError(translate(language, 'signup.invalidSMSOTP'));
    }
  };

  const handleSignup = async () => {
    setError('');
    setLoading(true);

    if (!firstName || !lastName || !captchaToken) {
      setError(translate(language, 'signup.firstNameRequired'));
      setLoading(false);
      return;
    }

    if (!validateEmail(email)) {
      setError(translate(language, 'signup.invalidEmail'));
      setLoading(false);
      return;
    }

    if (!validatePassword(password)) {
      setError(translate(language, 'signup.weakPassword'));
      setLoading(false);
      return;
    }

    if (password !== retypePassword) {
      setError(translate(language, 'signup.passwordMismatch'));
      setLoading(false);
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/createAccount`,
        {
          firstName,
          lastName,
          userEmail: email,
          userPassword: password,
          userPhone: phone,
          referralCode,
        },
      );
      navigate('/message', {
        state: {
          message: translate(language, 'signup.signupSuccess'),
          buttonText: translate(language, 'signup.returnToLogin'),
          buttonLink: '/login',
        },
      });
    } catch (error) {
      console.error('Error signing up:', error);
      setError(translate(language, 'signup.signupError'));
      setLoading(false);
    }
  };

  const checkPasswordMatch = (password, retypePassword) => {
    if (password && retypePassword) {
      if (password !== retypePassword) {
        setRetypePasswordError(translate(language, 'signup.passwordMismatch'));
      } else {
        setRetypePasswordError('');
      }
    } else {
      setRetypePasswordError('');
    }
  };

  const handlePasswordBlur = () => {
    checkPasswordMatch(password, retypePassword);
  };

  const handleRetypePasswordBlur = () => {
    checkPasswordMatch(password, retypePassword);
  };

  const handlePasswordChange = e => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    checkPasswordMatch(newPassword, retypePassword); // Pass current values
  };

  const handleRetypePasswordChange = e => {
    const newRetypePassword = e.target.value;
    setRetypePassword(newRetypePassword);
    checkPasswordMatch(password, newRetypePassword); // Pass current values
  };

  const isSignupDisabled =
    ((!email || !validateEmail(email) || !isEmailOtpVerified) && // If email is provided, it must be valid and verified
      (!phone || !validatePhone(phone) || !isSmsOtpVerified)) || // If phone is provided, it must be valid and verified
    !password ||
    password !== retypePassword ||
    !firstName ||
    !lastName ||
    !captchaToken;

  return (
    <>
      <TopNav text={translate(language, 'signup.pageTitle')} />
      <Container fontFamily={fontFamilies.regular}>
        <Text fontFamily={fontFamilies.bold}>
          {translate(language, 'signup.headerText')}
        </Text>
        {error && <ErrorText>{error}</ErrorText>}

        <RequiredFieldsNote>
          {translate(language, 'signup.requiredFieldsNote')}
        </RequiredFieldsNote>

        <UserInput
          fontFamily={fontFamilies.regular}
          type="text"
          placeholder={`${translate(language, 'signup.firstName')}*`}
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
        />
        <UserInput
          fontFamily={fontFamilies.regular}
          type="text"
          placeholder={`${translate(language, 'signup.lastName')}*`}
          value={lastName}
          onChange={e => setLastName(e.target.value)}
        />

        <UserInput
          fontFamily={fontFamilies.regular}
          type="password"
          placeholder={`${translate(language, 'signup.password')}*`}
          value={password}
          onChange={handlePasswordChange}
          onBlur={handlePasswordBlur}
        />
        <UserInput
          fontFamily={fontFamilies.regular}
          type="password"
          placeholder={`${translate(language, 'signup.retypePassword')}*`}
          value={retypePassword}
          onChange={handleRetypePasswordChange}
          onBlur={handleRetypePasswordBlur}
        />
        {retypePasswordError && <ErrorText>{retypePasswordError}</ErrorText>}

        <Note>{translate(language, 'signup.enterEitherEmailOrPhone')}</Note>

        <OtpContainer>
          <OTPInput
            fontFamily={fontFamilies.regular}
            type="email"
            placeholder={`${translate(language, 'signup.email')}*`}
            value={email}
            onChange={e => setEmail(e.target.value)}
            disabled={isEmailOtpVerified}
            autocomplete="username"
          />
          <SendOtpButton
            fontFamily={fontFamilies.semiBold}
            onClick={handleSendEmailOtp}
            disabled={
              !email ||
              !validateEmail(email) ||
              emailOtpCountdown > 0 ||
              isEmailOtpVerified
            }>
            {emailOtpCountdown > 0
              ? `${translate(language, 'signup.wait')} ${emailOtpCountdown}s`
              : translate(language, 'signup.send')}
          </SendOtpButton>
        </OtpContainer>

        <OtpContainer>
          <OTPInput
            fontFamily={fontFamilies.regular}
            type="text"
            placeholder={translate(language, 'signup.enterEmailOtp')}
            value={emailOtp}
            onChange={e => setEmailOtp(e.target.value)}
            disabled={!isEmailOtpFieldEnabled || isEmailOtpVerified}
          />
          <SendOtpButton
            fontFamily={fontFamilies.semiBold}
            onClick={handleVerifyEmailOtp}
            disabled={!emailOtp || isEmailOtpVerified}>
            {isEmailOtpVerified ? '✔' : translate(language, 'signup.verify')}
          </SendOtpButton>
        </OtpContainer>

        <OtpContainer>
          <OTPInput
            fontFamily={fontFamilies.regular}
            type="text"
            placeholder={`${translate(language, 'signup.phone')}*`}
            value={phone}
            onChange={e => setPhone(e.target.value)}
            disabled={isPhoneFieldDisabled || isSmsOtpVerified}
          />
          <SendOtpButton
            fontFamily={fontFamilies.semiBold}
            onClick={handleSendPhoneOtp}
            disabled={
              !phone ||
              !validatePhone(phone) ||
              smsOtpCountdown > 0 ||
              isSmsOtpVerified
            }>
            {smsOtpCountdown > 0
              ? `${translate(language, 'signup.wait')} ${smsOtpCountdown}s`
              : translate(language, 'signup.send')}
          </SendOtpButton>
        </OtpContainer>
        <Note>{translate(language, 'signup.phoneNote')}</Note>

        <OtpContainer>
          <OTPInput
            fontFamily={fontFamilies.regular}
            type="text"
            placeholder={translate(language, 'signup.enterSmsOtp')}
            value={phoneOtp}
            onChange={e => setPhoneOtp(e.target.value)}
            disabled={!isPhoneOtpFieldEnabled || isSmsOtpVerified}
          />
          <SendOtpButton
            fontFamily={fontFamilies.semiBold}
            onClick={handleVerifyPhoneOtp}
            disabled={!phoneOtp || isSmsOtpVerified}>
            {isSmsOtpVerified ? '✔' : translate(language, 'signup.verify')}
          </SendOtpButton>
        </OtpContainer>

        <UserInput
          fontFamily={fontFamilies.regular}
          type="text"
          placeholder={translate(language, 'signup.referralCode')}
          value={referralCode}
          onChange={e => setReferralCode(e.target.value)}
        />

        {/* CAPTCHA - Cloudflare Turnstile */}
        <div id="captcha-container"></div>

        {loading ? (
          <CircularProgress />
        ) : (
          <SignupButton
            fontFamily={fontFamilies.semiBold}
            onClick={handleSignup}
            disabled={isSignupDisabled}>
            {translate(language, 'signup.signup')}
          </SignupButton>
        )}
      </Container>
    </>
  );
};

export default Signup;
