import axios from 'axios';
import Cookies from 'js-cookie';
import {useContext, useState} from 'react';
import styled from 'styled-components';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';
import {Overlay, Spinner} from '../styles/LoadingOverlay';

// Styled components
const Container = styled.div`
  margin: 30px;
  font-family: ${props => props.fontFamily};
  height: 60vh;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LanguageSwitch = styled.div`
  display: flex;
  align-items: center;
`;

const LanguageButton = styled.button`
  cursor: pointer;
  padding: 5px 10px;
  background-color: ${props => (props.active ? '#3DDBD1' : '#f0f0f0')};
  border: none;
  border-radius: 5px;
  color: ${props => (props.active ? 'white' : '#3DDBD1')};
  font-family: ${props => props.fontFamily};
  margin-left: 5px;
`;

const Item = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CheckIcon = styled.span`
  margin-left: 10px;
  color: green;
  font-size: 20px;
`;

const Settings = () => {
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language, switchLanguage} = useContext(LanguageContext);
  const [userInfo] = useState(
    Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : {},
  );
  const [biometricEnabled, setBiometricEnabled] = useState(
    Cookies.get('userInfo')
      ? JSON.parse(Cookies.get('userInfo')).biometric
      : {},
  );

  const [isLoading] = useState(false);

  const handleLanguageSwitch = lang => {
    switchLanguage(lang);
    localStorage.setItem('Language', lang);
  };

  const handleEnableBiometricLogin = async () => {
    // Check if the app is running inside a WebView
    const isNative = navigator.userAgent.includes('wv');

    if (isNative) {
      // If it's a WebView, trigger native biometric login via postMessage
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'enableBiometricLogin',
          userInfo: {
            name: userInfo.firstName + ' ' + userInfo.lastName,
            displayName: userInfo.firstName,
            email: userInfo.email,
          },
        }),
      );
      return;
    }

    if (!window.PublicKeyCredential) {
      alert('Biometric login is not supported by this browser.');
      return;
    }

    try {
      // Step 1: Generate a challenge on the frontend (random byte array)
      const challenge = new Uint8Array(32);
      window.crypto.getRandomValues(challenge);

      // Step 2: Create PublicKeyCredentialCreationOptions object
      const publicKey = {
        challenge: challenge,
        rp: {
          name: 'CarbonMetA Carbon Point',
        },
        user: {
          id: new Uint8Array(
            [...Array(16)].map(() => Math.floor(Math.random() * 256)),
          ),
          name: userInfo.firstName + ' ' + userInfo.lastName,
          displayName: userInfo.firstName,
          email: userInfo.email,
        },
        pubKeyCredParams: [{type: 'public-key', alg: -7}], // ES256
        timeout: 60000,
        authenticatorSelection: {
          authenticatorAttachment: 'platform',
          userVerification: 'required',
        },
        attestation: 'direct',
      };

      const credential = await navigator.credentials.create({publicKey});
      const credentialResponse = {
        userId: credential.id,
        rawId: btoa(String.fromCharCode(...new Uint8Array(credential.rawId))),
        type: credential.type,
        response: {
          clientDataJSON: btoa(
            String.fromCharCode(
              ...new Uint8Array(credential.response.clientDataJSON),
            ),
          ),
          attestationObject: btoa(
            String.fromCharCode(
              ...new Uint8Array(credential.response.attestationObject),
            ),
          ),
        },
      };

      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/registerBiometric`,
        {
          credential: {
            ...credentialResponse,
            publicKey: credentialResponse.response.attestationObject,
            challenge: btoa(String.fromCharCode(...challenge)),
          },
        },
      );

      //will use this store when login
      const rawIdBase64 = btoa(
        String.fromCharCode(...new Uint8Array(credential.rawId)),
      );
      localStorage.setItem('credentialId', rawIdBase64);
      setBiometricEnabled(true);
      alert('Biometric login enabled successfully.');
    } catch (err) {
      console.error('Failed to create a credential:', err);
      alert('Error enabling biometric: Not Supported Device');
    }
  };

  return (
    <>
      {isLoading && ( // Show the overlay when loading
        <Overlay>
          <Spinner />
        </Overlay>
      )}
      <TopNav text={translate(language, 'settings.language')} />
      <Container fontFamily={fontFamilies.regular}>
        <Header>
          <div>{translate(language, 'settings.language')}</div>
          <LanguageSwitch>
            <LanguageButton
              fontFamily={fontFamilies.regular}
              active={language === 'en'}
              onClick={() => handleLanguageSwitch('en')}>
              EN
            </LanguageButton>
            <LanguageButton
              fontFamily={fontFamilies.regular}
              active={language === 'zh'}
              onClick={() => handleLanguageSwitch('zh')}>
              简
            </LanguageButton>
            <LanguageButton
              fontFamily={fontFamilies.regular}
              active={language === 'zhhk'}
              onClick={() => handleLanguageSwitch('zhhk')}>
              繁
            </LanguageButton>
          </LanguageSwitch>
        </Header>
        <Item onClick={handleEnableBiometricLogin}>
          {translate(language, 'settings.enableBiometricLogin')}{' '}
          {biometricEnabled && <CheckIcon>&#x2714;</CheckIcon>}
        </Item>
      </Container>
      <BottomNav />
    </>
  );
};

export default Settings;
