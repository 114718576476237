import {useState, useContext, useRef, useEffect} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Ensure you have this icon or replace with similar
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import QrScanner from 'qr-scanner';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  font-family: ${props => props.fontFamily};
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 10px 0;
`;

const UserInput = styled.input`
  width: 100%;
  height: 40px;
  padding: 0px 46px 0px 16px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #ffffff;
  font-family: ${props => props.fontFamily};
`;

const ScanIcon = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  width: 100%;
  height: 40px;
  margin-top: 30px;
  padding: 0px;
  border: none;
  border-radius: 20px;
  background-color: #3ddbd1;
  color: #ffffff;
  font-family: ${props => props.fontFamily};
  cursor: pointer;
`;

const SuccessMessage = styled.div`
  font-size: 20px;
  color: green;
  margin-top: 20px;
`;

const Details = styled.div`
  font-size: 16px;
  color: #333;
`;

const ReviewContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
`;

const QRScannerModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ScannerContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
`;

const CameraPreview = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Overlay = styled.div`
  position: absolute;
  bottom: 100px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
  pointer-events: none;
`;

const Frame = styled.div`
  width: 80%;
  height: 50%;
  border: 5px solid white;
  border-radius: 12px;
  z-index: 1;
`;

const InstructionText = styled.div`
  color: white;
  margin-top: 20px;
  font-size: 1rem;
  text-align: center;
`;

const ErrorText = styled.div`
  color: red;
  font-size: 1rem;
  margin-top: 10px;
`;

const CloseButton = styled(CloseIcon)`
  color: white;
  font-size: 4rem;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
  cursor: pointer;
`;

const SpinnerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Transfer = () => {
  const {language} = useContext(LanguageContext);
  const {fontFamilies} = useFontFamilyByLanguage();

  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [recipient, setRecipient] = useState('');
  const [isReviewing, setIsReviewing] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const videoElementRef = useRef();
  const [errorMessage, setErrorMessage] = useState('');

  const scanQR = () => {
    setShowScanner(true);
  };

  const handleScan = result => {
    if (result) {
      setRecipient(result?.text);
      setShowScanner(false);
    }
  };

  const handleError = error => {
    console.error('Error scanning QR code:', error);
    setError(translate(language, 'points.scanError'));
  };

  const handleReview = () => {
    if (recipient && amount) {
      setIsReviewing(true);
    } else {
      setError(translate(language, 'points.fillAllFields'));
    }
  };

  const handleConfirmTransfer = async () => {
    setLoading(true);
    setError('');

    const token = localStorage.getItem('token');
    setTransactionDetails(null);
    try {
      const response = await axios.post(
        '/api/transaction/transfer',
        {
          recipient,
          amount,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.status === 200) {
        setTransactionDetails(response.data);
        setLoading(false);
      } else {
        setError(translate(language, 'points.transferFail'));
        setLoading(false);
      }
    } catch (err) {
      console.error('Transfer Error:', err);
      setError(translate(language, 'points.transferFail'));
      setLoading(false);
    }
  };

  const handleClose = () => {
    if (videoElementRef.current) {
      const tracks = videoElementRef.current.srcObject?.getTracks();
      if (tracks) {
        tracks.forEach(track => track.stop());
      }
    }
    setShowScanner(false);
  };

  useEffect(() => {
    let qrScanner;

    if (showScanner && videoElementRef.current) {
      qrScanner = new QrScanner(
        videoElementRef.current,
        result => {
          if (result) {
            setRecipient(result.data);
            setShowScanner(false);
          }
        },
        {
          highlightScanRegion: true,
          facingMode: 'environment',
        },
      );

      qrScanner.start().catch(error => {
        console.error('QR Scanner Error:', error);
        setErrorMessage(translate(language, 'qrScan.focusOnQrCode'));
      });
    }

    return () => {
      if (qrScanner) {
        qrScanner.stop();
      }
    };
  }, [showScanner]);

  return (
    <>
      <TopNav text={translate(language, 'points.transfer')} />
      {loading && (
        <SpinnerOverlay>
          <CircularProgress size={60} />
        </SpinnerOverlay>
      )}
      <Container fontFamily={fontFamilies.regular}>
        {error && <p style={{color: 'red'}}>{error}</p>}
        {!transactionDetails ? (
          <>
            {!isReviewing ? (
              <>
                <InputContainer>
                  <UserInput
                    type="text"
                    placeholder={translate(language, 'points.recipient')}
                    value={recipient}
                    onChange={e => setRecipient(e.target.value)}
                    fontFamily={fontFamilies.regular}
                  />
                  <ScanIcon onClick={() => scanQR()}>
                    <QrCodeScannerIcon />
                  </ScanIcon>
                </InputContainer>
                <UserInput
                  type="number"
                  placeholder={translate(language, 'points.amount')}
                  value={amount}
                  onChange={e => setAmount(e.target.value)}
                  fontFamily={fontFamilies.regular}
                />
                <Button
                  onClick={handleReview}
                  fontFamily={fontFamilies.semiBold}
                  disabled={!recipient || !amount}>
                  {translate(language, 'points.review')}
                </Button>
              </>
            ) : (
              <ReviewContainer>
                <h3>{translate(language, 'points.reviewTransfer')}</h3>
                <p>
                  {translate(language, 'points.recipient')}: {recipient}
                </p>
                <p>
                  {translate(language, 'points.amount')}: {amount}
                </p>
                <Button
                  onClick={handleConfirmTransfer}
                  fontFamily={fontFamilies.semiBold}
                  disabled={loading}>
                  {loading
                    ? translate(language, 'points.process')
                    : translate(language, 'points.confirm')}
                </Button>
                <Button
                  onClick={() => setIsReviewing(false)}
                  fontFamily={fontFamilies.semiBold}
                  style={{backgroundColor: '#ccc', marginTop: '10px'}}>
                  {translate(language, 'points.edit')}
                </Button>
              </ReviewContainer>
            )}
          </>
        ) : (
          <>
            <CheckCircleIcon style={{fontSize: 60, color: '#3BB143'}} />
            <SuccessMessage>
              {translate(language, 'points.transactionSuccess')}
            </SuccessMessage>
            <Details>
              <p>{translate(language, 'points.recipient')}:</p>
              {transactionDetails.recipient.email && (
                <p>{transactionDetails.recipient.email}</p>
              )}
              {transactionDetails.recipient.phone && (
                <p>{transactionDetails.recipient.phone}</p>
              )}
              {transactionDetails.recipient.shortId && (
                <p>{transactionDetails.recipient.shortId}</p>
              )}
              <p>
                {translate(language, 'points.amount')}:{' '}
                {transactionDetails.amount}
              </p>
              <p>
                {translate(language, 'points.transactionId')}:{' '}
                {transactionDetails.transactionId}
              </p>
              <br />
              <p>{translate(language, 'points.transferProcessed')}</p>
            </Details>
          </>
        )}
      </Container>
      <BottomNav />
      {showScanner && (
        <QRScannerModal>
          <ScannerContainer>
            <CameraPreview>
              <video
                ref={videoElementRef}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </CameraPreview>
            <Overlay>
              <InstructionText>
                {translate(language, 'qrScan.aimAtQrCode')}
              </InstructionText>
              <InstructionText>
                {translate(language, 'qrScan.fitQrCodeInBox')}
              </InstructionText>
              <InstructionText>
                {translate(language, 'qrScan.additionalMessage')}
              </InstructionText>
              {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
            </Overlay>
            <CloseButton onClick={handleClose} />
          </ScannerContainer>
        </QRScannerModal>
      )}
    </>
  );
};

export default Transfer;
