import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  height: 80vh;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 190px;
  background-image: url(./image.jpeg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Text = styled.div`
  color: #030303;
  font-family: ${props => props.fontFamily};
  margin: 20px 0;
`;

const SubText = styled.div`
  color: #030303;
  font-family: ${props => props.fontFamily};
  margin: 10px 0;
`;

const UserInput = styled.input`
  width: 100%;
  height: 40px;
  margin: 10px 0;
  padding: 0px 16px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #ffffff;
  font-family: ${props => props.fontFamily};
`;

const ForgotPasswordLabel = styled.button`
  width: 100%;
  height: 40px;
  margin-top: 20px;
  padding: 0px 16px;
  border: none;
  border-radius: 20px;
  background-color: #cdeee3;
  color: #000000;
  font-family: ${props => props.fontFamily};
  cursor: pointer;
`;

const Link = styled.a`
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  margin-top: 15px;
`;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const validateEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\\.,;:\s@"]+\.)+[^<>()[\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const handleForgotPassword = async () => {
    try {
      setLoading(true);

      if (!validateEmail(email)) {
        setError(translate(language, 'forgotPassword.invalidEmail'));
        setLoading(false);
        return;
      }

      const response = await axios.post('/api/auth/forgotPassword', {email});
      if (response.status === 200) {
        navigate('/message', {
          state: {
            message: translate(language, 'forgotPassword.checkEmail'),
            buttonText: translate(language, 'forgotPassword.returnToLogin'),
            buttonLink: '/login',
          },
        });
      } else {
        setError(translate(language, 'forgotPassword.error'));
      }
    } catch (error) {
      console.error(error);
      setError(translate(language, 'forgotPassword.error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        <Text fontFamily={fontFamilies.bold}>
          {translate(language, 'forgotPassword.headerText')}
        </Text>
        <SubText fontFamily={fontFamilies.regular}>
          {translate(language, 'forgotPassword.subText')}
        </SubText>
        {error && <div style={{color: 'red'}}>{error}</div>}
        <UserInput
          fontFamily={fontFamilies.regular}
          type="text"
          placeholder={translate(language, 'forgotPassword.emailPlaceholder')}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        {loading ? (
          <CircularProgress />
        ) : (
          <ForgotPasswordLabel
            fontFamily={fontFamilies.regular}
            onClick={handleForgotPassword}>
            {translate(language, 'forgotPassword.submit')}
          </ForgotPasswordLabel>
        )}
      </Container>
    </>
  );
};

export default ForgotPassword;
