// src/pages/privacy.js
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 20px auto;
  max-width: 800px;
  font-family: Arial, sans-serif;
  padding: 20px;
  line-height: 1.6;
  color: #333;
`;

const Heading = styled.h1`
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  margin-top: 15px;
`;

const Paragraph = styled.p`
  margin: 10px 0;
`;

const PrivacyPage = () => {
  return (
    <Container>
      <Heading>Privacy Policy</Heading>
      <Paragraph>
        <strong>Effective Date:</strong> 2024-09-20
      </Paragraph>
      <Paragraph>
        Welcome to the Carbon Point app, developed and delivered by NexVision
        Limited ("we," "our," or "us"). This Privacy Policy outlines how we
        collect, use, disclose, and safeguard your personal information when you
        use the Carbon Point app. By using the app, you agree to the terms
        outlined in this policy.
      </Paragraph>

      <SectionTitle>1. Information We Collect</SectionTitle>
      <Paragraph>
        We collect the following personal information for account registration
        purposes:
      </Paragraph>
      <ul>
        <li>Email Address</li>
        <li>Name</li>
        <li>Phone Number</li>
      </ul>
      <Paragraph>
        This information is essential for setting up your account and enabling
        access to the app's features.
      </Paragraph>

      <SectionTitle>2. Camera Usage</SectionTitle>
      <Paragraph>
        The app requests access to your device's camera solely for the purpose
        of scanning QR codes. This allows you to access various functionalities
        within the app, such as redeeming points or coupons.
      </Paragraph>

      <SectionTitle>3. Purpose of the App</SectionTitle>
      <Paragraph>
        The Carbon Point app is designed to manage a point and membership
        system. Users can earn, manage, and redeem points for various benefits,
        including coupons provided by merchants.
      </Paragraph>

      <SectionTitle>4. News and Events</SectionTitle>
      <Paragraph>
        All news and events published in the app are managed and provided by
        Carbon Point. We do not control or take responsibility for the content
        published by Carbon Point.
      </Paragraph>

      <SectionTitle>5. Points and Coupon Redemption</SectionTitle>
      <Paragraph>
        Points accumulated within the app can be used to redeem coupons. These
        coupons are provided by third-party merchants, and they can be used for
        discounts, offers, or other benefits as specified.
      </Paragraph>

      <SectionTitle>6. Rights and Decisions on Coupons</SectionTitle>
      <Paragraph>
        All coupons are provided and managed by the respective merchants, and
        the final decision regarding the usage, terms, and validity of coupons
        lies with the merchant. NexVision and Carbon Point do not have any
        control over the rights, conditions, or restrictions imposed by
        merchants on the coupons.
      </Paragraph>

      <SectionTitle>7. How We Protect Your Information</SectionTitle>
      <Paragraph>
        We prioritize the security of your personal information. We employ
        industry-standard security measures to protect your data from
        unauthorized access, disclosure, alteration, or destruction.
      </Paragraph>

      <SectionTitle>8. Data Sharing and Disclosure</SectionTitle>
      <Paragraph>
        Your personal information is used strictly for the purposes mentioned
        above and is not shared with third parties except:
      </Paragraph>
      <ul>
        <li>When required by law or legal processes.</li>
        <li>
          To protect the rights and safety of users, NexVision, or Carbon Point.
        </li>
        <li>
          With authorized service providers who assist us in maintaining the
          app’s functionality.
        </li>
      </ul>

      <SectionTitle>9. Changes to This Privacy Policy</SectionTitle>
      <Paragraph>
        We may update this Privacy Policy periodically to reflect changes in our
        practices or for other operational, legal, or regulatory reasons. Any
        changes will be posted within the app, and we encourage you to review
        this policy regularly.
      </Paragraph>

      <SectionTitle>10. Contact Us</SectionTitle>
      <Paragraph>
        If you have any questions, concerns, or requests regarding this Privacy
        Policy or your personal information, please contact us at:
      </Paragraph>
      <Paragraph>
        NexVision Limited
        <br />
        West Wing, 2/F, 822 Lai Chi Kok Road, Cheung Sha Wan, Hong Kong
        <br />
        Phone: +852 6016 0319
        <br />
        Email: admin@nexvision.cc
      </Paragraph>

      <Paragraph>
        By using the Carbon Point app, you acknowledge that you have read,
        understood, and agreed to the terms of this Privacy Policy.
      </Paragraph>
    </Container>
  );
};

export default PrivacyPage;
